import { createSelector } from 'reselect'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface LoadingState {
  isLoading: boolean
  progress: number
}

/**
 * posts storeの型定義
 */
const initialState: LoadingState = {
  isLoading: false,
  progress: 0
}

/**
 * selector
 * @param state
 * @returns
 */
export const loadingSelector = (state: RootState) => state.loading

export const getIsLoading = createSelector(
  loadingSelector,
  (loading) => loading.isLoading
)

export const getProgress = createSelector(
  loadingSelector,
  (loading) => loading.progress
)

/**
 * SLICE
 * reducer
 */
const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    start(state) {
      state.isLoading = true
    },
    stop(state) {
      state.isLoading = false
    },
    addProgress(state, action: PayloadAction<number>) {
      state.progress += action.payload
    },
    resetProgress(state) {
      state.progress = 0
    }
  }
})

export default loading
