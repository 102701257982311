import { Auth } from 'aws-amplify'
import awsconfig from '../aws-exports'
const urlBase = awsconfig.yoor_app_api_url_base

// ネストされているPartial
export type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<NestedPartial<R>>
    : NestedPartial<T[K]>
}

/**
 * AccessToken取得
 */
export const getAccessToken = async () => {
  try {
    const resp = await Auth.currentSession()
    return resp.getAccessToken().getJwtToken()
  } catch (err) {
    return ''
  }
}

export const getV1ApiHeaders = async (method: string): Promise<RequestInit> => {
  const token = await getAccessToken()
  return {
    method: method,
    mode: 'cors',
    headers:
      token !== ''
        ? {
            'Content-Type': 'application/json',
            'X-Api-Version': 'v1',
            Authorization: `Bearer ${token}`
          }
        : {
            'Content-Type': 'application/json',
            'X-Api-Version': 'v1'
          }
  }
}

/**
 * backendとの疎通確認
 */
export const PingApi = async () => {
  const accessToken = await getAccessToken()
  const response = await fetch(`${urlBase}/api/ping`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': 'v1',
      Authorization: `Bearer ${accessToken}`
    }
  })
  return await response.json()
}
