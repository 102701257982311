import { Auth } from 'aws-amplify'

import awsconfig from '../aws-exports'

const urlBase = awsconfig.yoor_app_sls_api_url_base

const YoorAppApiGateway = () => {
  const request_media_credential = async () => {
    const resp = await Auth.currentSession()
    const accessToken = resp.getAccessToken().getJwtToken()
    const apiurl = `${urlBase}/request_media_credential`
    const response = await fetch(apiurl, {
      method: 'GET',
      mode: 'cors',
      //credentials: 'same-origin',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken
        //'Cookie': `yoor.media.session:${accessToken}; yoor.media.stage=${awsconfig.yoor_media_stage}`
      }
    })
    return await response.json()
  }

  const get_stripe_customer_portal_url = async () => {
    const resp = await Auth.currentSession()
    const accessToken = resp.getAccessToken().getJwtToken()
    const apiurl = urlBase + '/get_stripe_customer_portal_url'
    const response = await fetch(apiurl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken
      }
    })
    return response
  }

  const updateEmail = async (email: String) => {
    const resp = await Auth.currentSession()
    const accessToken = resp.getAccessToken().getJwtToken()
    const apiurl = urlBase + '/update_email'
    const response = await fetch(apiurl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken
      },
      body: JSON.stringify({ email: email })
    })
    return await response.json()
  }

  const updatePassword = async (passwordHash: String) => {
    const resp = await Auth.currentSession()
    const accessToken = resp.getAccessToken().getJwtToken()
    const apiurl = urlBase + '/update_password'
    const response = await fetch(apiurl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: accessToken
      },
      body: JSON.stringify({ passwordHash: passwordHash })
    })
    return await response.json()
  }

  return {
    request_media_credential: request_media_credential,
    get_stripe_customer_portal_url: get_stripe_customer_portal_url,
    updateEmail: updateEmail,
    updatePassword: updatePassword
  }
}

const YoorAppApi = YoorAppApiGateway()

export default YoorAppApi
