import { requestV1 } from './base'
import { GoogleTokens, LineTokens, FacebookCredential } from './response_types'

// twitter
export const getTwitterRequestTokenURL = async (body: object) => {
  return await requestV1('post', '/oauth/twitter/request_token_url', body)
}

export const verifyTwitterCredentials = async (body: object) => {
  return await requestV1('post', '/oauth/twitter/verify_credentials', body)
}

// google
export const verifyGoogleCode = async (body: object) => {
  return await requestV1<GoogleTokens>('post', '/oauth/google/verify_code', body)
}

// line
export const verifyLineCode = async (body: object) => {
  return await requestV1<LineTokens>('post', '/oauth/line/verify_code', body)
}

// facebook
export const verifyFacebookCredential = async (body: object) => {
  return await requestV1<FacebookCredential>('post', '/oauth/facebook/verify_credential', body)
}
