import awsconfig from '../aws-exports'
const urlBase = awsconfig.yoor_app_api_url_base

export const getAllKeywordsV1Api = async () => {
  const response = await fetch(`${urlBase}/api/keywords`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': 'v1',
    }
  })
  return await response.json()
}
export const getTopKeywordsV1Api = async () => {
  const response = await fetch(`${urlBase}/api/keywords/top`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': 'v1',
    }
  })
    return response.json()
}

export const getKeywordListV1Api = async () => {
  const response = await fetch(`${urlBase}/api/keywords/list`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': 'v1',
    }
  })
  return response.json()
}


export const getKeywordV1Api = async (keyword: string) => {
  return await fetch(`${urlBase}/api/keywords/${keyword}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Version': 'v1',
    }
  })
}

