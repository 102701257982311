import image_keyword_01 from './components/landingpage/assets/image_keyword_01.webp'
import image_keyword_02 from './components/landingpage/assets/image_keyword_02.webp'
import image_keyword_03 from './components/landingpage/assets/image_keyword_03.webp'
import image_keyword_04 from './components/landingpage/assets/image_keyword_04.webp'
import image_keyword_05 from './components/landingpage/assets/image_keyword_05.webp'

// 投稿、コメント関連
export const imageUploadLimit = 30
export const videoUploadLimit = 1
// 1MB: 1024 3GB: 3221225472
export const videoUploadByteSizeLimit = 3221225472
export const videoUploadGBSizeLimit = '3GB'
export const postMaxTitleLength = 30
export const postMaxContentLength = 30000

export const landingPageFixedKeywords: Array<{
  path: string
  name: string
  image: string
}> = [
  {
    path: '/keyword/community',
    name: 'コミュニティ',
    image: image_keyword_01
  },
  {
    path: '/keyword/creator',
    name: 'クリエイター',
    image: image_keyword_02
  },
  {
    path: '/keyword/video',
    name: '映像',
    image: image_keyword_03
  },
  {
    path: '/keyword/fanclub',
    name: 'ファンクラブ',
    image: image_keyword_04
  },
  {
    path: '/keyword/learn',
    name: '学び',
    image: image_keyword_05
  }
]
export const OWNER_PERMISSION_STAFF = ['admin_owner', 'general_owner', 'general']

export const IMAGE_OPTIMIZER = {
  size: {
    // 複数枚ある場合のサムネイル幅
    thumbnails: 'width=300',
    // 単体。　大きめに表示するため
    thumbnail: 'width=700',
    // アイコン。投稿編集画面で使われる。
    icon: 'width=50'
  }
}

export const DOOR_CONST = {
  SHORT_DESCRIPTION_MAX_LENGTH: 120,
  DESCRIPTION_MAX_LENGTH: 5000,
  RECOMMEND_FOR_LENGTH: 40,
  RECOMMEND_VOLUME: 4
}

export default {
  personalYearMin: 1900,
  roomsLimit: 30,
  // localStorageKeys
  amplifyFederatedInfoKey: 'aws-amplify-federatedInfo',
  amplifySigninWithHostedUIKey: 'amplify-signin-with-hostedUI',
  cognitoIdentityIdKeyPrefix: 'aws.cognito.identity-id.',
  cognitoIdentityProvidersKeyPrefix: 'aws.cognito.identity-providers.',
  yoorFacebookLoginStateKey: 'yoor-facebook-login-state',
  yoorLineLoginStateKey: 'yoor-line-login-state'
}
