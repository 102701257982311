import { Snackbar as MUISnackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import alertModules, { getIsOpen, getSeverity, getText } from 'modules/alert'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'

export default function Snackbar() {
  const isOpen = useSelector((state: RootState) => getIsOpen(state))
  const text = useSelector((state: RootState) => getText(state))
  const severity = useSelector((state: RootState) => getSeverity(state))
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <MUISnackbar
        open={isOpen}
        autoHideDuration={6000}
        key={text}
        onClose={(event?: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway') {
            return
          }
          dispatch(alertModules.actions.setClose())
        }}
      >
        <Alert
          onClose={() => {
            dispatch(alertModules.actions.setClose())
          }}
          severity={severity}
        >
          {text}
        </Alert>
      </MUISnackbar>
    </React.Fragment>
  )
}
