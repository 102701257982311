import { requestV1 } from 'apis/base'
import { MyDoor } from 'apis/response_types'

export const getMyDoor = async () => {
  return await requestV1<MyDoor>('get', '/me/door')
}

export const createMeDoor = async (data: {
  door: {
    title: string
    description: string
    keyword_ids: number[]
  }
  plan?: {
    title: string
    description: string
    subscription_cost: number
  }
}) => {
  return await requestV1<MyDoor>('post', '/me/door', data)
}
