import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'
import {SamplePost} from 'apis/response_types'

interface SamplePostsState {
  samplePosts: SamplePost[] | null
}

/**
 * posts storeの型定義
 */
const initialState: SamplePostsState = {
    samplePosts: null
}

/**
 * selector
 * @param state
 * @returns
 */
export const samplePostsSelector = (state: RootState) => state.samplePosts
export const getSamplePosts = createSelector(samplePostsSelector, (sample_posts) => sample_posts.samplePosts)

/**
 * SLICE
 * reducer
 */
const samplePosts = createSlice({
  name: 'samplePosts',
  initialState,
  reducers: {
    setSamplePosts(state, action: PayloadAction<SamplePost[]>) {
      state.samplePosts = action.payload
    }
  }
})

 export default samplePosts
