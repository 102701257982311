import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import posts from './modules/posts'
import me from './modules/me'
import alert from './modules/alert'
import subscriptionPlan from './modules/subscriptionPlan'
import loading from './modules/loading'
import samplePosts from 'modules/sample_posts'
import door from 'modules/door'
import leftMenu from 'modules/left_menu'
import topBanner from 'modules/top_banner'
import doorSettingsNotice from 'modules/door_settings_notice'

const rootReducer = combineReducers({
  posts: posts.reducer,
  me: me.reducer,
  alert: alert.reducer,
  subscriptionPlan: subscriptionPlan.reducer,
  loading: loading.reducer,
  samplePosts: samplePosts.reducer,
  door: door.reducer,
  leftMenu: leftMenu.reducer,
  topBanner: topBanner.reducer,
  doorSettingsNotice: doorSettingsNotice.reducer
})

const store = configureStore({
  reducer: rootReducer
})
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // E

export default store
