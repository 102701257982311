import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type LeftMenuType = 'door' | 'owner' | 'plan' | 'personal' | 'review'

interface LeftMenuState {
  type: LeftMenuType | null
  validTypes: Array<LeftMenuType>
}

const initialState: LeftMenuState = {
  type: null,
  validTypes: []
}

export const leftMenuSelector = (state: RootState) => state.leftMenu
export const getType = createSelector(leftMenuSelector, (s) => s.type)
export const getValidTypes = createSelector(leftMenuSelector, (s) => s.validTypes)

/**
 * SLICE
 * reducer
 */
const leftMenu = createSlice({
  name: 'left_menu',
  initialState,
  reducers: {
    setType(
      state,
      action: PayloadAction<LeftMenuType | null>
    ) {
      state.type = action.payload
    },
    setValidTypes(
      state,
      action: PayloadAction<Array<LeftMenuType>>
    ) {
      state.validTypes = action.payload
    },
  }
})

export default leftMenu

