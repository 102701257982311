import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface DoorState {
  customize: boolean
  isPublished: boolean
  postableRoomCount: number
  isLockedRoom: boolean
}

/**
 * posts storeの型定義
 */
const initialState: DoorState = {
  customize: false,
  isPublished: false,
  postableRoomCount: 0,
  isLockedRoom: true
}

/**
 * selector
 * @param state
 * @returns
 */
export const doorSelector = (state: RootState) => state.door

export const getDoorCustomize = createSelector(
  doorSelector,
  (door: DoorState) => door.customize
)
export const getDoorIsPublished = createSelector(
  doorSelector,
  (door: DoorState) => door.isPublished
)
export const getPostableRoomCount = createSelector(
  doorSelector,
  (door: DoorState) => door.postableRoomCount
)
export const getIsLockedRoom = createSelector(
  doorSelector,
  (door: DoorState) => door.isLockedRoom
)

/**
 * SLICE
 * reducer
 */
const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setCustomize(state, action: PayloadAction<{ customize: boolean }>) {
      state.customize = action.payload.customize
    },
    setIsPublished(state, action: PayloadAction<{ isPublished: boolean }>) {
      state.isPublished = action.payload.isPublished
    },
    setPostableRoomCount(state, action: PayloadAction<{ postableRoomCount: number }>) {
      state.postableRoomCount = action.payload.postableRoomCount
    },
    setIsLockedRoom(state, action: PayloadAction<{ isLockedRoom: boolean }>) {
      state.isLockedRoom = action.payload.isLockedRoom
    }
  }
})

export default loading
