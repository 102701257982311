import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface SubscriptionPlanState {
  editId: number
  selectedId: number
}

/**
 * posts storeの型定義
 */
const initialState: SubscriptionPlanState = {
  editId: 0,
  selectedId: 0
}

/**
 * selector
 * @param state
 * @returns
 */
export const subscriptionPlanSelector = (state: RootState) =>
  state.subscriptionPlan

export const getEditID = createSelector(
  subscriptionPlanSelector,
  (subscriptionPlan) => subscriptionPlan.editId
)

/**
 * SLICE
 * reducer
 */
const subscripitonPlan = createSlice({
  name: 'subscripitonPlan',
  initialState,
  reducers: {
    setSelectedId: (state, action) => { state.selectedId = action.payload },
    setEditID(
      state,
      action: PayloadAction<{
        id: number
      }>
    ) {
      const payload = action.payload
      state.editId = payload.id
    }
  }
})

export default subscripitonPlan
