import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LikeV1, PaginatedPostsV1, PostV1 } from '../apis/door/PostV1'
import { RootState } from '../store'

interface AlertState {
  isOpen: boolean
  text: string
  severity: 'error' | 'warning' | 'info' | 'success'
}

/**
 * posts storeの型定義
 */
const initialState: AlertState = {
  isOpen: false,
  text: '',
  severity: 'info'
}

/**
 * selector
 * @param state
 * @returns
 */
export const alertSelector = (state: RootState) => state.alert

export const getIsOpen = createSelector(alertSelector, (alert) => alert.isOpen)
export const getText = createSelector(alertSelector, (alert) => alert.text)
export const getSeverity = createSelector(
  alertSelector,
  (alert) => alert.severity
)

/**
 * SLICE
 * reducer
 */
const alert = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert(
      state,
      action: PayloadAction<{
        text: string
        severity: 'error' | 'warning' | 'info' | 'success'
      }>
    ) {
      const alert = action.payload

      state.text = alert.text
      state.severity = alert.severity
      state.isOpen = true
    },
    setClose(state) {
      state.isOpen = false
    }
  }
})

export default alert
