import { requestV1 } from './base'

export interface DoorAsStaff {
  header_image_url: string
  title: string
  twitter_screen_name: string
  position?: string
}
export interface DoorListAsStaff {
  door: DoorAsStaff
  doors: Array<DoorAsStaff>
}

export const getDoorsAsStaff = async () => {
  return await requestV1<DoorListAsStaff>('get', `/staffs/doors`)
}
