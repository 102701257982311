import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type TopBannerContent = 'door_cannot_publish' | 'door_can_publish' | 'door_personal_info_required' | 'door_owner_personal_info_required'

interface TopBannerState {
  isOpen: boolean
  content: TopBannerContent | null
  configPath: string | null
  doorId: string | null
}

const initialState: TopBannerState = {
  isOpen: false,
  content: null,
  configPath: null,
  doorId: null
}

export const topBannerSelector = (state: RootState) => state.topBanner
export const getIsOpen = createSelector(topBannerSelector, (banner) => banner.isOpen)
export const getContent = createSelector(topBannerSelector, (banner) => banner.content)
export const getConfigPath = createSelector(topBannerSelector, (banner) => banner.configPath)
export const getDoorId = createSelector(topBannerSelector, (banner) => banner.doorId)

/**
 * SLICE
 * reducer
 */
const topBanner = createSlice({
  name: 'top_banner',
  initialState,
  reducers: {
    setMessage(
      state,
      action: PayloadAction<{
        content: TopBannerContent | null
        configPath: string | null
      }>
    ) {
      state.content = action.payload.content
      state.configPath = action.payload.configPath
      state.isOpen = true
    },
    setClose(state) {
      state.isOpen = false
      state.content = null
    }
  }
})

export default topBanner
