import { requestV1 } from './base'
import { Rooms, RoomDetail } from './response_types'

export const getRooms = (doorID: string) => {
  return requestV1<Rooms>('get', `/doors/${doorID}/rooms`)
}

export const readRoom = (doorID: string, roomId: string) => {
  return requestV1<null>('get', `/doors/${doorID}/rooms/${roomId}/read`)
}

export const getRoom = (doorID: string, room_id: number) => {
  return requestV1<RoomDetail>('get', `/doors/${doorID}/rooms/${room_id}`)
}

export const getPopup = (doorID: string, closedAt: string | null) => {
  let path = `/doors/${doorID}/room_popup`
  if(closedAt) path += `?closed_at=${closedAt}`
  return requestV1<RoomPopup>('get', path)
}

export interface RoomPopup {
  id: number
  description: string
  title: string
  url: string
  image_url: string
  published_at: string
}

export const roomPopupClosedLocalStorageKey = 'RoomPopupClosed'
