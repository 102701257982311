/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
/**
 *
 * CONFIG FOR HDEV ENVIRONMENT
 *
 */
const awsmobile = {
    "ENV_NAME": "production",
    "published": "2021-07-20T02:23:35.288503",
    "layer_version": 381,
    "layer_updated": "2021-07-20T02:20:11.285700",
    "git_branch": "production",
    "git_commit": "32b74d841f7249e0bdc0a99178e978f854d608f0",
    "ga_id": "UA-175453734-1",
    "stripe_public_key": "pk_live_51HIPgFIkuFGEdKzBZhEDbWaGe2uj9moiqGxy2kABw992Jm5E8QnzqNoepyipgGZJfwLjntPZELCO6qbsLzjvUrAb00gFc5J9tj",
    "yoor_media_stage": "app",
    "yoor_app_get_page": "https://app.yoor.jp/get_user_page",
    "yoor_app_sls_api_url_base": "https://app.yoor.jp",
    "yoor_app_api_url_base": "https://api.yoor.jp",
    "twitter_sls_api_url_base": "https://twauth.app.yoor.jp",
    "twitter_signin_callback_url": "https://yoor.jp/signin",
    "twitter_signup_callback_url": "https://yoor.jp/signup/oauth/twitter",
    "twitter_connect_callback_url": 'https://yoor.jp/account/settings/connect',
    "yoor_share_url_base":"https://share.yoor.jp",
    "google_signin_callback_url": 'https://yoor.jp/signin',
    "google_signup_callback_url": 'https://yoor.jp/signup/oauth/google',
    "google_connect_callback_url": 'https://yoor.jp/account/settings/connect',
    "google_client_id": '630619260790-p1okvtoh3hrg4faekl14u35jjb24bdd9.apps.googleusercontent.com',
    "facebook_app_id": '1304734930351696',
    "facebook_signin_callback_url": 'https://yoor.jp/signin',
    "facebook_signup_callback_url": 'https://yoor.jp/signup/oauth/facebook',
    "facebook_connect_callback_url": 'https://yoor.jp/account/settings/connect',
    "line_channel_id": '2000355428',
    "line_signin_callback_url": 'https://yoor.jp/signin',
    "line_signup_callback_url": 'https://yoor.jp/signup/oauth/line',
    "line_connect_callback_url": 'https://yoor.jp/account/settings/connect',
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:a67d175a-5749-47c3-bd39-03fde59f41fa",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_1yZ2EjV3l",
    "aws_user_pools_web_client_id": "365fv3j3u4d8im0coun8dhcviq",
    "oauth": {
        "domain": "yoor-production.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://yoor.jp/signin",
        "redirectSignOut": "http://yoor.jp/signin",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://yztznocikndetivt7h6sabjice.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "app-kimuradev-module-appcdn",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1uqzc37euq6y2.cloudfront.net",
    "aws_user_files_s3_bucket": "cdn-closed-yoor-production-module",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
