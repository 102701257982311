import { requestV1 } from '../base'

export interface MeUserCredential {
  google_id: string | null
  facebook_id: string | null
  twitter_id: string | null
  line_id: string | null
  google_identity_id: string | null
  facebook_identity_id: string | null
  twitter_identity_id: string | null
  line_identity_id: string | null
  google_account_name: string | null
  facebook_account_name: string | null
  twitter_account_name: string | null
  line_account_name: string | null
}

export const getMeUserCredential = async () => {
  return await requestV1<MeUserCredential>('get', '/me/user_credential')
}

export const connectMeUserCredential = async (body: object) => {
  return await requestV1<MeUserCredential>('post', '/me/user_credential/connect', body)
}

export const disconnectMeUserCredential = async (body: object) => {
  return await requestV1<MeUserCredential>('post', '/me/user_credential/disconnect', body)
}
