import React from 'react'
import ReactDOM from 'react-dom'
import dayjs from "dayjs";
import ja from 'dayjs/locale/ja'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import store from './store'
import { Provider } from 'react-redux'
import awsconfig from './aws-exports'
import { HelmetProvider } from 'react-helmet-async'
import MetaTag from 'components/common/MetaTag'

declare global {
  interface Window {
    gtag: Gtag.Gtag
  }
}
const history = createBrowserHistory()
const trackingId = awsconfig.ga_id

history.listen(({ pathname }) => {
  window.gtag('set', trackingId, { page_path: pathname })
  window.gtag('event', 'page_view')
})

dayjs.locale(ja)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* HelmetProviderはDOMのルート付近で一度だけ定義するタグである為、ここで定義する */}
      <HelmetProvider>
        {/* ページ共通のメタ情報。ページ毎で固有にしたい場合、各ページ側でMetaTagタグを使い再定義すること */}
        <MetaTag
          title="オンラインサロン / コミュニティ運営なら YOOR「ユア」"
          description="YOORは、オンラインサロン専用トークルームを完備した、コミュニティやファンクラブ、オンラインレッスンの場として利用できるプラットフォームです。"
        />
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
