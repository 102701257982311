import { CognitoUser } from 'amazon-cognito-identity-js'
import Amplify, { Auth } from 'aws-amplify'
import React, { useContext, useEffect, useReducer } from 'react'
import { createBrowserHistory } from 'history'

import Cookies from 'universal-cookie'

import { SignUpParams } from '@aws-amplify/auth/lib-esm/types'
import { GoogleOAuthProvider } from '@react-oauth/google'

import YoorAppApi from '../utils/YoorAppApiGateway'

import awsconfig from '../aws-exports'
import { logger } from '../utils/logger'
import { InvitationLocalStorageKeys } from 'apis/invitation'
import { roomPopupClosedLocalStorageKey } from 'apis/rooms'
import { CognitoAccessToken, CognitoIdToken } from 'amazon-cognito-identity-js'
import { CognitoSigninToken } from 'apis/response_types'

export interface AuthState {
  isAuthenticated: boolean
  isLoading: boolean
  user?: CognitoUser
  error?: any
  signInError?: any
}
const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: '',
  signInError: {}
}

const unAuthorizedState: AuthState = {
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  error: '',
  signInError: {}
}

interface ResResultAndError {
  result: any | undefined
  error: any | undefined
}

interface ResEmailUpdate {
  result: any | undefined
  error: any | undefined
}
const stub = (): never => {
  throw new Error('You forgot to wrap your component in <CognitoAuthProvider>.')
}
export const initialContext = {
  signIn: stub,
  signUp: stub,
  getCurrentUsersCognitoSub: stub,
  getCurrentUserName: stub,
  confirmSignUp: stub,
  confirmEmailWithCognito: stub,
  signOut: stub,
  emailUpdate: stub,
  passwordUpdate: stub,
  createCognitoSession: stub,
  authState: initialState
}

export type LoginOption = {
  email: string
  password: string
}
interface ICognitoAuthProviderParams {
  amplifyConfig: {
    aws_project_region: string
    aws_cognito_identity_pool_id: string
    aws_cognito_region: string
    aws_user_pools_id: string
    aws_user_pools_web_client_id: string
    oauth: {
      domain: string
      scope: string[]
      redirectSignIn: string
      redirectSignOut: string
      responseType: string
    }
    federationTarget: string
  }
  children: any
}

interface CognitoError {
  code: string
  name: string
  message: string
}

interface SignUpResponse {
  user?: CognitoUser
  error?: CognitoError
}

interface IAuthContext {
  signIn: (signInOption: LoginOption) => Promise<any>
  signUp: (params: SignUpParams) => Promise<SignUpResponse>
  confirmSignUp: (params: any) => Promise<void>
  getCurrentUsersCognitoSub: () => Promise<string | undefined>
  getCurrentUserName: () => Promise<string | undefined>
  signOut: (global?: boolean) => void
  emailUpdate: (email: String) => Promise<ResEmailUpdate>
  passwordUpdate: (
    oldPass: string,
    newPass: string
  ) => Promise<ResResultAndError>
  confirmEmailWithCognito: (code: string) => Promise<ResResultAndError>
  createCognitoSession: (user: CognitoSigninToken) => Promise<void>
  authState: AuthState
}
export const AuthContext = React.createContext<IAuthContext>(initialContext)
export const useAuth = () => useContext(AuthContext)

const reducer = (state: AuthState, action: any) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, isLoading: true } as AuthState
    case 'AUTHORIZED':
      return { ...state, isAuthenticated: true, isLoading: false } as AuthState
    case 'LOADED':
      return { ...state, isLoading: false } as AuthState
    case 'UNAUTHORIZED':
      return {
        ...state,
        isAuthenticated: false,
        signInError: action.data.error
      } as AuthState
    case 'UPDATE_USER':
      return { ...state, user: action.data.user } as AuthState
    case 'ERROR':
      return { ...state, error: action.data.error } as AuthState
    case 'SIGNOUT':
      return unAuthorizedState
  }
  return state
}

export default function CognitoAuthProvider(props: ICognitoAuthProviderParams) {
  Amplify.configure(props.amplifyConfig)
  const [authState, authDispatch] = useReducer(reducer, initialState)
  const history = createBrowserHistory()
  useEffect(() => {
    checkAuthenticated()
    currentAuthenticatedUser()
  }, [])

  const checkAuthenticated = () => {
    authState.isLoading = true
    authDispatch({ type: 'LOADING', data: {} })
    Auth.currentSession()
      .then((data) => {
        if (data) {
          authState.isLoading = false
          authState.isAuthenticated = true
          authDispatch({ type: 'AUTHORIZED', data: {} })
        }
      })
      .catch((err) => {
        logger.debug('current session error', err)
      })
      .finally(() => {
        authState.isLoading = false
        authDispatch({ type: 'LOADED', data: {} })
      })
  }

  const currentAuthenticatedUser = async (): Promise<void> => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser()

      authState.user = user
      authDispatch({ type: 'UPDATE_USER', data: { user: user } })

      if (user) {
        user.getUserAttributes(function (err, result) {
          // currentAuthenticatedUserが取得出来た場合は常にメール認証完了しているか確認する。
          if (result) {
            for (const attribute of result) {
              if (
                attribute.getName() === 'email_verified' &&
                attribute.getValue() === 'false'
              ) {
                // email_verified属性がfalse / メール未認証はメール認証画面に遷移させる。
                if (
                  !history.location.pathname.startsWith(
                    '/account/settings/email/'
                  )
                ) {
                  window.location.href = `/account/settings/email/${user.getUsername()}?resend=yes`
                }
              }
            }
          }
        })
      }
    } catch (err) {
      logger.debug('ERROR Authentication: ', err)
    }
  }

  const getCurrentUsersCognitoSub = async (): Promise<string | undefined> => {
    let user: any = authState.user
    if (!user) {
      try {
        user = await Auth.currentAuthenticatedUser()

        if (!user.hasOwnProperty('attributes')) {
          return undefined
        }
        // const user = await Auth.currentUserInfo()
        //   .then((data) => {
        //     logger.debug('DATA IN getCurrentUsersCognitoSub', data)
        //     return data
        //   })
        //   .catch((error) => {
        //     return undefined
        //   })

        // authState.user = user
        // authDispatch({ type: 'UPDATE_USER', data: { user: user } })
        authState.user = user
        authDispatch({ type: 'UPDATE_USER', data: { user: user } })
        const cookies = new Cookies()
        const resp = await Auth.currentSession()
        const accessToken = resp.getAccessToken().getJwtToken()
        if (accessToken !== cookies.get('yoor.media.session')) {
          logger.debug('---------- ACCESS TOKEN -------------')
          logger.debug(accessToken)
          //logger.debug('---------- SET COOKIE -------------')
          //logger.debug(cookies.get('yoor.media.session'))
          //cookies.set('yoor.media.stage',
          //  awsconfig.yoor_media_stage,
          //  {
          //    path: '/private',
          //    domain: '.yoor.jp',
          //    expires: new Date(Date.now()+3600),
          //    httpOnly: true,
          //    secure: true,
          //    sameSite: 'lax'  // debug purpose otherwise Lax
          //  });
          //cookies.set('yoor.media.session',
          //  accessToken,
          //  {
          //    path: '/private',
          //    domain: '.yoor.jp',
          //    expires: new Date(Date.now()+3600),
          //    httpOnly: true,
          //    secure: true,
          //    sameSite: 'lax'  // debug purpose otherwise Lax
          //  });
          //}
          // await YoorAppApi.request_media_credential()
        }
      } catch (err) {
        logger.debug('ERROR in getCurrentUsersCognitoSub:', err)
        authDispatch({ type: 'ERROR', data: { error: err } })
      } finally {
        return user?.attributes?.sub
      }
    }
    return user?.attributes.sub
  }

  const getCurrentUserName = async (): Promise<string | undefined> => {
    let user: any = authState.user
    if (!user) {
      try {
        user = await Auth.currentAuthenticatedUser()
        // const user = await Auth.currentUserInfo()
        //   .then((data) => {
        //     logger.debug('DATA IN getCurrentUsersCognitoSub', data)
        //     return data
        //   })
        //   .catch((error) => {
        //     return undefined
        //   })

        // authState.user = user
        // authDispatch({ type: 'UPDATE_USER', data: { user: user } })
        authState.user = user
        authDispatch({ type: 'UPDATE_USER', data: { user: user } })
      } catch (err) {
        authDispatch({ type: 'ERROR', data: { error: err } })
      }
    }
    return user?.username
  }

  const signIn = async ({ email, password }: LoginOption): Promise<any> => {
    authDispatch({ type: 'LOADING', data: {} })
    try {
      await Auth.signIn(email, password)
      authDispatch({ type: 'AUTHORIZED', data: {} })
    } catch (err) {
      logger.debug('error signing in', err)
      authDispatch({ type: 'UNAUTHORIZED', data: { error: err } })
      // @ts-ignore
      return err.code
    }
    authDispatch({ type: 'LOADED', data: {} })
    return ''
  }

  const signUp = async (param: SignUpParams): Promise<SignUpResponse> => {
    authDispatch({ type: 'LOADING', data: {} })
    let result
    let error
    try {
      result = await Auth.signUp(param)
      const userSub = { userSub: result.userSub }

      authDispatch({
        type: 'UPDATE_USER',
        data: { user: Object.assign(result.user, userSub) }
      })
    } catch (err) {
      error = err
      logger.debug('error signing up', err)
      authDispatch({ type: 'ERROR', data: { error: err } })
    }
    authDispatch({ type: 'LOADED', data: {} })
    // @ts-ignore
    return { user: result?.user, error: error }
  }

  const confirmSignUp = async ({ username, code }: any): Promise<void> => {
    authDispatch({ type: 'LOADING', data: {} })
    try {
      await Auth.confirmSignUp(username, code)
      authDispatch({ type: 'AUTHORIZED', data: {} })
    } catch (err) {
      logger.debug('error confirming sign up', err)
      authDispatch({ type: 'ERROR', data: { error: err } })
    }
    authDispatch({ type: 'LOADED', data: {} })
  }

  const signOut = async (global = false) => {
    const excludes = [roomPopupClosedLocalStorageKey, ...InvitationLocalStorageKeys]
    await authDispatch({ type: 'LOADING', data: {} })
    await Auth.signOut({ global: global })
      .then(() => {
        authDispatch({ type: 'SIGNOUT', data: {} })
        for (let key in localStorage) {
          if (excludes.includes(key)) continue
          localStorage.removeItem(key)
        }
      })
      .catch((err) => {
        logger.debug('error signing out: ', err)
        authDispatch({ type: 'ERROR', data: { error: err } })
      })
      .finally(() => {
        authDispatch({ type: 'LOADED', data: {} })
      })
  }

  const emailUpdate = async (email: String): Promise<ResEmailUpdate> => {
    authDispatch({ type: 'LOADING', data: {} })
    let result
    let error
    try {
      const data = await YoorAppApi.updateEmail(email)
      if (data.status !== 'SUCCESS') {
        throw new Error(`email ${email} is already used.`)
      }
      const user = await Auth.currentAuthenticatedUser()
      result = await Auth.updateUserAttributes(user, {
        email: email,
        email_verified: true
      })
      logger.debug('DATA in updateEmail', data)
      // authDispatch({ type: 'UPDATE_USER', data: { user: result.user } })
      authDispatch({ type: 'LOADED', data: {} })
      return { result: result ? result : 'NO DATA', error: error }
    } catch (err) {
      error = err
      logger.debug('error in update email: ', err)
      authDispatch({ type: 'ERROR', data: { error: err } })
      authDispatch({ type: 'LOADED', data: {} })
      return { result: {}, error: error }
    } finally {
      authDispatch({ type: 'LOADED', data: {} })
    }
    // return { user: result?.user, error: error }
  }

  const confirmEmailWithCognito = async (
    code: string
  ): Promise<ResResultAndError> => {
    let error
    let result
    authDispatch({ type: 'LOADING', data: {} })
    try {
      result = await Auth.verifyCurrentUserAttributeSubmit('email', code)
      // authDispatch({ type: 'UPDATE_USER', data: { user: result.user } })
    } catch (err) {
      error = err
      logger.debug('error signing up:', err)
      authDispatch({ type: 'ERROR', data: { error: err } })
    }
    authDispatch({ type: 'LOADED', data: {} })
    return { result: result, error: error }
  }

  const passwordUpdate = async (
    oldPasswordHash: string,
    newPasswordHash: string
  ): Promise<ResResultAndError> => {
    const currentUser = await Auth.currentAuthenticatedUser()
    let result
    let error
    try {
      await Auth.changePassword(currentUser, oldPasswordHash, newPasswordHash)
      await YoorAppApi.updatePassword(newPasswordHash)
      result = 'SUCCESS'
    } catch (err) {
      error = err
    }
    return { result: result, error: error }
  }

  // 認証状態にするためユーザープールのローカルストレージ値を手動で生成する
  const createCognitoSession = async (session: CognitoSigninToken): Promise<void> => {
    const username = session.username

    // https://github.com/aws-amplify/amplify-js/blob/be53c6187057728a9e2cf24265324fd1f470e5e0/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1489
    const prefix = `CognitoIdentityServiceProvider.${awsconfig.aws_user_pools_web_client_id}`

    localStorage.setItem(`${prefix}.${username}.idToken`, session.idToken)
    localStorage.setItem(`${prefix}.${username}.accessToken`, session.accessToken)
    localStorage.setItem(`${prefix}.${username}.refreshToken`, session.refreshToken)
    localStorage.setItem(`${prefix}.${username}.userData`, JSON.stringify(session.userData))
    localStorage.setItem(`${prefix}.LastAuthUser`, username)

    // https://github.com/aws-amplify/amplify-js/blob/be53c6187057728a9e2cf24265324fd1f470e5e0/packages/amazon-cognito-identity-js/src/CognitoUserSession.js#L70C25-L70C25
    const now = Math.floor(Number(new Date()) / 1000)
    const iat = Math.min(
      new CognitoAccessToken({ AccessToken: session.accessToken }).getIssuedAt(),
      new CognitoIdToken({ IdToken: session.idToken }).getIssuedAt()
    )
    const clockDrift = now - iat

    localStorage.setItem(`${prefix}.${username}.clockDrift`, String(clockDrift))
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        getCurrentUsersCognitoSub,
        getCurrentUserName,
        signIn,
        signUp,
        confirmSignUp,
        signOut,
        emailUpdate,
        passwordUpdate,
        confirmEmailWithCognito,
        createCognitoSession
      }}
    >
      <GoogleOAuthProvider clientId={awsconfig.google_client_id}>
        {props.children}
      </GoogleOAuthProvider>
    </AuthContext.Provider>
  )
}
