import React from 'react'
import { Link } from 'react-router-dom'
type MenuType = 'account' | 'creditcard' | 'bank' | 'mail'

interface Props {
  type: MenuType
  hasDoor: Boolean
}

export default function SettingNav(props: Props) {
  // ナビ内容
  const nav: Array<{
    name: string
    type: MenuType
    to: string
  }> = [
    {
      name: 'アカウント情報',
      type: 'account',
      to: '/account/settings'
    },
    {
      name: 'カード管理',
      type: 'creditcard',
      to: '/account/settings/creditcard'
    },
    {
      name: '通知設定',
      type: 'mail',
      to: '/account/settings/notification'
    }
  ]
  // サロン開設済の場合、口座情報リンクを表示
  if (props.hasDoor) {
    nav.splice(2, 0, {
      name: '口座情報',
      type: 'bank',
      to: '/account/settings/bank'
    })
  }

  return (
    <div className="settingNav">
      <h2 className="settingNavHeadinng">アカウント設定</h2>
      <nav className="settingTab">
        {nav.map((n, i) => (
          <Link
            key={n.to}
            rel="nofollow"
            to={n.to}
            className={`settingTab__item ${
              n.type === props.type ? 'is-active' : ''
            }`}
          >
            {n.name}
          </Link>
        ))}
      </nav>
    </div>
  )
}
