import React from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
  title: string
  description?: string | undefined
  ogUrl?: string | undefined
  ogType?: string | undefined
  ogImage?: string | undefined
}

export default function MetaTag(props: Props) {
  return (
    // Helmetでメタ情報を置き換える
    <Helmet>
      {/* title */}
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />

      {/* description */}
      {props.description && <meta name="description" content={props.description} /> }
      {props.description && <meta property="og:description" content={props.description} /> }

      {/* og tag */}
      {props.ogUrl && <meta property="og:url" content={props.ogUrl} />}
      {props.ogType && <meta property="og:type" content={props.ogType} />}
      {props.ogImage && <meta property="og:image" content={props.ogImage} />}
    </Helmet>
  )
}
