import './LoadingAnimation.css'

import React from 'react'

export default function LoadingAnimation() {
  return (
    <div className="cssload-container">
      <div className="cssload-loading">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
    </div>
  )
}

export function WithLoadingAnimation(props: {
  loading: boolean
  component: any
}) {
  return props.loading ? <LoadingAnimation /> : props.component
}
