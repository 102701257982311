import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LikeV1, PaginatedPostsV1, PostV1 } from '../apis/door/PostV1'
import { RootState } from '../store'

interface PostsState {
  paginatedPosts: PaginatedPostsV1
  scrollTo: number
  paginatedPostsUserSub: string
  newPostedId: string | null
}

/**
 * posts storeの型定義
 */
const initialState: PostsState = {
  paginatedPosts: { posts: [], current_page: 0, total_pages: 0 },
  scrollTo: 0,
  paginatedPostsUserSub: '',
  newPostedId: null
}

/**
 * selector
 * @param state
 * @returns
 */
export const postsSelector = (state: RootState) => state.posts

export const getPaginatedPosts = createSelector(
  postsSelector,
  (posts) => posts.paginatedPosts
)

export const getNewPostedId = createSelector(
  postsSelector,
  (posts) => posts.newPostedId
)

interface UpdatePostLike {
  postID: string
  like: LikeV1
}

/**
 * SLICE
 * reducer
 */
const posts = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    setPosts(state, action: PayloadAction<PostV1[]>) {
      const posts: PostV1[] = action.payload
      state.paginatedPosts.posts = posts
    },
    setPaginatedPostsUserSub(state, action: PayloadAction<string>) {
      const userSub: string = action.payload
      state.paginatedPostsUserSub = userSub
    },
    setUpdatePostLikes(state, action: PayloadAction<UpdatePostLike>) {
      const payload: UpdatePostLike = action.payload
      const index = state.paginatedPosts.posts.findIndex(
        (p) => p.dynamo_id === payload.postID
      )
      state.paginatedPosts.posts[index].like = payload.like
    },
    addPosts(state, action: PayloadAction<PostV1[]>) {
      const posts: PostV1[] = action.payload
      state.paginatedPosts.posts.push(...posts)
    },
    setNextPage(state, action: PayloadAction<number>) {
      const next_page: number = action.payload
      state.paginatedPosts.current_page = next_page
    },
    setScrollTo(state, action: PayloadAction<number>) {
      const scrollTo: number = action.payload
      state.scrollTo = scrollTo
    },
    setNewPostedId(state, action: PayloadAction<string | null>) {
      const postedId: string | null = action.payload
      state.newPostedId = postedId
    }
  }
})

export default posts
