import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface DoorSettingsNoticeState {
  status: 'in_private' | 'published'
  canPublish: boolean
}

/**
 * posts storeの型定義
 */
const initialState: DoorSettingsNoticeState = {
    status: 'published',
    canPublish: false
}

/**
 * selector
 * @param state
 * @returns
 */
export const doorSettingsNoticeSelector = (state: RootState) => state.doorSettingsNotice

export const getCanPublish = createSelector(doorSettingsNoticeSelector, (notice) => notice.canPublish)
export const getIsInPrivate = createSelector(doorSettingsNoticeSelector, (notice) => notice.status === 'in_private')
export const getIsPublished = createSelector(doorSettingsNoticeSelector, (notice) => notice.status === 'published')
/**
 * SLICE
 * reducer
 */
const doorSettingsNotice = createSlice({
  name: 'door_settings_notice',
  initialState,
  reducers: {
    setStatus(state, action: PayloadAction<'in_private' | 'published'>) {
      state.status = action.payload
    },
    setCanPublish(state, action: PayloadAction<boolean>) {
      state.canPublish = action.payload
    }
  }
})

export default doorSettingsNotice

