import React from 'react'
import { useSelector } from 'react-redux'
import { Backdrop, CircularProgress } from '@material-ui/core'

import { getIsLoading } from 'modules/loading'
import { RootState } from 'store'

export default function Loading() {
  const isLoading = useSelector((state: RootState) => getIsLoading(state))

  return (
    <React.Fragment>
      <Backdrop
        style={{
          color: '#fff',
          zIndex: 2000
        }}
        open={isLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </React.Fragment>
  )
}
