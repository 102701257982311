import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'

import awsconfig from '../aws-exports'
import { logger } from './logger'
import { cognitoSignin } from '../apis/user_credentials'
import { CognitoSigninToken, FacebookCredential } from '../apis/response_types'
import constants from '../constants'

const {
  amplifyFederatedInfoKey,
  amplifySigninWithHostedUIKey,
  cognitoIdentityIdKeyPrefix,
  cognitoIdentityProvidersKeyPrefix,
  yoorFacebookLoginStateKey
} = constants

const FacebookConnect = () => {
  const cleanLocalStorage = async () => {
    localStorage.removeItem(amplifyFederatedInfoKey)
    localStorage.removeItem(amplifySigninWithHostedUIKey)
    const iidKey =
      cognitoIdentityIdKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    localStorage.removeItem(iidKey)
    const iipKey =
      cognitoIdentityProvidersKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    localStorage.removeItem(iipKey)
  }

  /**
   * localStrageのamplify federatedInfoのデータをもとにyoor APIを実行し認証情報を取得する
   */
  const getCognitoSigninToken: () => Promise<CognitoSigninToken> = async () => {
    const info = localStorage.getItem(amplifyFederatedInfoKey)
    if (!info) throw new Error('no federated info')

    const infoParsed = JSON.parse(info)
    const iidKey =
      cognitoIdentityIdKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    const identityId = localStorage.getItem(iidKey)

    const response = await cognitoSignin({
      provider: 'facebook',
      facebook_access_token: infoParsed.token,
      identity_id: identityId
    })

    return response.data
  }

  const getIdentityId = async (token: string) => {
    const credential = await new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsconfig.aws_cognito_identity_pool_id,
      Logins: { 'graph.facebook.com': token }
    }, { region: awsconfig.aws_cognito_region })

    try {
      await credential.getPromise()
    } catch(err) {
      if (err) {
        logger.debug('Error: ' + err)
        return ''
      }
    }
    logger.debug('Cognito Identity Id: ' + credential.identityId)
    return credential.identityId
  }

  const federatedSignIn = async (fbInfo: FacebookCredential) => {
    const identityId = await getIdentityId(fbInfo.accessToken)

    const cred = await Auth.federatedSignIn('facebook', {
      token: fbInfo.accessToken,
      identity_id: identityId,
      expires_at: 3600 * 24 * 30 * 1000 + new Date().getTime() // the expiration timestamp
    }, {
      name: fbInfo.user.name,
      email: fbInfo.user.email
    })

    return {
      credentials: cred,
      identityId: identityId
    }
  }

  const verifyState = (state: string) => {
    const storedState = localStorage.getItem(yoorFacebookLoginStateKey)
    if  (state === storedState) {
      localStorage.removeItem(yoorFacebookLoginStateKey)
      return true
    } else {
      return false
    }
  }

  const createState = (): string => {
    let state = localStorage.getItem(yoorFacebookLoginStateKey)
    if (!state) {
      state = Math.random().toString(32).substring(2)
      localStorage.setItem(yoorFacebookLoginStateKey, state)
    }
    return state
  }

  return {
    getCognitoSigninToken: getCognitoSigninToken,
    federatedSignIn: federatedSignIn,
    cleanLocalStorage: cleanLocalStorage,
    verifyState: verifyState,
    createState: createState
  }
}

const Facebook = FacebookConnect()

export default Facebook
