import { requestV1 } from './base'
import { UserCredential, CognitoSigninToken } from './response_types'

// 3650 API廃止
// export const getUserCredential = async (body: object) => {
//   return await requestV1<UserCredential>('post', '/user_credential/show', body)
// }

export const cognitoSignin = async (body: object) => {
  return await requestV1<CognitoSigninToken>('post', '/user_credential/cognito_signin', body)
}

export const createUserCredential = async (body: object) => {
  return await requestV1<UserCredential>('post', '/user_credential', body)
}

export const updateUserCredentialPassword = async (body: object) => {
  return await requestV1<UserCredential>('post', '/user_credential/password', body)
}
