import jwtDecode, { JwtPayload } from "jwt-decode"
import { Auth } from 'aws-amplify'
import AWS from 'aws-sdk'
import awsconfig from '../aws-exports'

import { logger } from './logger'
import { cognitoSignin } from '../apis/user_credentials'
import { CognitoSigninToken } from '../apis/response_types'
import constants from '../constants'

const {
  amplifyFederatedInfoKey,
  amplifySigninWithHostedUIKey,
  cognitoIdentityIdKeyPrefix,
  cognitoIdentityProvidersKeyPrefix,
  yoorLineLoginStateKey
} = constants

interface LineJwtPayload extends JwtPayload {
  name: string
  email: string
}

const LineConnect = () => {
  const decodeIdToken = (idToken: string): LineJwtPayload => jwtDecode(idToken)

  const cleanLocalStorage = async () => {
    localStorage.removeItem(amplifyFederatedInfoKey)
    localStorage.removeItem(amplifySigninWithHostedUIKey)
    const iidKey =
      cognitoIdentityIdKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    localStorage.removeItem(iidKey)
    const iipKey =
      cognitoIdentityProvidersKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    localStorage.removeItem(iipKey)
  }

  /**
   * LINEのIDトークンベースでyoor APIを実行し認証情報を取得する
   */
  const getCognitoSigninToken: (idToken: string) => Promise<CognitoSigninToken> = async (idToken: string) => {
    const response = await cognitoSignin({
      provider: 'line',
      line_id_token: idToken,
      identity_id: null
    })
    return response.data
  }

  /**
   * LINEの認証ページへリダイレクトする
   */
  const authorize = (redirect_uri: string) => {
    const queryParams = new URLSearchParams({
      response_type: 'code',
      client_id: awsconfig.line_channel_id,
      redirect_uri: redirect_uri,
      state: createToken(yoorLineLoginStateKey),
      scope: 'profile openid email',
      disable_ios_auto_login: 'true',
      // nonce: createToken(yoorLineLoginNonceKey)
    });
    window.location.href = `https://access.line.me/oauth2/v2.1/authorize?${queryParams.toString()}`
  }

  const authorizeSignUp = () => {
    authorize(awsconfig.line_signup_callback_url)
  }

  const authorizeSignIn = () => {
    authorize(awsconfig.line_signin_callback_url)
  }

  const authorizeConnect = () => {
    authorize(awsconfig.line_connect_callback_url)
  }

  const verifyToken = (key: string, token: string) => {
    const storedToken = localStorage.getItem(key)
    if  (token === storedToken) {
      localStorage.removeItem(key)
      return true
    } else {
      return false
    }
  }

  const createToken = (key: string): string => {
    let token = localStorage.getItem(key)
    if (!token) {
      token = Math.random().toString(32).substring(2)
      localStorage.setItem(key, token)
    }
    return token
  }

  const verifyState = (state: string) => {
    return verifyToken(yoorLineLoginStateKey, state)
  }

  return {
    decodeIdtoken: decodeIdToken,
    getCognitoSigninToken: getCognitoSigninToken,
    cleanLocalStorage: cleanLocalStorage,
    authorizeSignUp: authorizeSignUp,
    authorizeSignIn: authorizeSignIn,
    authorizeConnect: authorizeConnect,
    verifyState: verifyState
  }
}

const Line = LineConnect()

export default Line
