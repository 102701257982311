
import awsconfig from '../aws-exports'
export const logger = require('simple-node-logger').createSimpleLogger();


if (awsconfig.ENV_NAME == 'production'){
    logger.setLevel('warn')
  } else if (awsconfig.ENV_NAME == 'staging'){
    logger.setLevel('debug')
  } else{
    logger.setLevel('debug')
  }
  