import { requestV1 } from './base'
import { getMe } from 'apis/me'

interface DoorDetailLight {
  title: string
  description: string
  door_screen_name: string
  twitter_screen_name: string
  icon_image_url: string
  header_image_url: string
}
interface PlanDetailLight {
  title: string
  description: string
}
export interface Invitation {
  valid: boolean
  error: string | null
  plans: Array<PlanDetailLight> | null
  door: DoorDetailLight | null
}
export type InvitationPosition =
  | 'admin_owner'
  | 'general_owner'
  | 'general'
  | 'member'

export interface PositionSelection {
  position: InvitationPosition
  available_volume: number
}
export interface PlanSelection {
  id: number
  title: string
  subscription_cost: number
  attendable: boolean
}
export interface InvitationSelections {
  position_selected: string
  position_selections: PositionSelection[]
  plan_selections: PlanSelection[]
}
export interface NewInvitation {
  door_id: string | undefined
  position: string | null
  plan_ids: Array<string> | null
  email?: string
}
export const StaffStateKey = 'StaffState'
export const InvitationLocalStorageKey = 'invitationToken'
export const InvitationModalClosed = 'invitationModalClosed'
export const InvitationExecuted = 'invitationExecuted'
export const InvalidInvitation = 'invalidInvitation'
export const InvitationAlreadyAttended = 'invitationAlreadyAttended'
export const InvitationPathReg = /^\/confirm/
export const InvitationLocalStorageKeys = [
  InvitationLocalStorageKey,
  InvitationModalClosed,
  StaffStateKey
]
export const setStaffStateKey = (salonId: string) => {
  localStorage.setItem(StaffStateKey, salonId)
}
export const removeStaffStateKey = () => {
  localStorage.removeItem(StaffStateKey)
}
export const getStaffStateKey = () => {
  return localStorage.getItem(StaffStateKey)
}
export const setInvitationAlreadyAttended = () => {
  localStorage.setItem(InvalidInvitation, '1')
}
export const removeInvitationAlreadyAttended = () => {
  localStorage.removeItem(InvalidInvitation)
}
export const getInvitationAlreadyAttended = () => {
  return localStorage.getItem(InvalidInvitation)
}
export const setInvalidInvitation = (token: string) => {
  localStorage.setItem(InvalidInvitation, token)
}
export const removeInvalidInvitation = () => {
  localStorage.removeItem(InvalidInvitation)
}
export const getInvalidInvitation = () => {
  return localStorage.getItem(InvalidInvitation)
}
export const setInvitationTokenToStorage = (token: string) => {
  localStorage.setItem(InvitationLocalStorageKey, token)
  removeInvitationModalClosed()
}
export const setInvitationExecuted = () => {
  localStorage.setItem(InvitationExecuted, '1')
}
export const removeInvitationExecuted = () => {
  localStorage.removeItem(InvitationExecuted)
}
export const getInvitationExecuted = () => {
  return localStorage.getItem(InvitationExecuted)
}
export const getInvitationTokenFromStorage = () => {
  return localStorage.getItem(InvitationLocalStorageKey)
}
export const getInvitationModalClosed = () => {
  return localStorage.getItem(InvitationModalClosed)
}
export const getInvitation = async (token: string) => {
  return await requestV1<Invitation>('get', `/invitation?token=${token}`)
}
export const getInvitationSelections = async (door_id: string | undefined) => {
  let path = '/invitation/selections'
  if (door_id !== undefined) path += `?door_id=${door_id}`
  return await requestV1<InvitationSelections>('get', path)
}
export const generateInvitation = async (newInvitation: NewInvitation) => {
  return await requestV1<any>('post', `/invitation/generate`, { newInvitation })
}
export const execInvitation = async (token: string) => {
  return await requestV1<any>('post', `/invitation`, { token })
}
export const removeInvitationToken = () => {
  localStorage.removeItem(InvitationLocalStorageKey)
  removeInvitationModalClosed()
}
export const removeInvitationModalClosed = () => {
  localStorage.removeItem(InvitationModalClosed)
}
export const invitationModalClose = () => {
  localStorage.setItem(InvitationModalClosed, '1')
}
export const removeInvalidInvitationState = async () => {
  try {
    const token = localStorage.getItem(InvitationLocalStorageKey)
    if (token !== null) {
      const response = await getInvitation(token)
      if (!response.data.valid && response.data.error === null)
        removeInvitationToken()
    }
  } catch (err) {}
}
export const isNotificableState = (invitation: Invitation) => {
  return invitation.valid || invitation.error
}
export const toExecPath = async (invitation: Invitation) => {
  let path = ''
  if (invitation.plans) {
    path = '/invitation/confirm'
  } else {
    const response = await getMe()
    const userScreenName = response.data.user_public.twitter_screen_name
    path = `/door/${userScreenName}`
  }
  return path
}
export const confirmPath = '/invitation/confirm'
export const toTargetDoorPath = (invitation: Invitation) => {
  const salonId = invitation.door?.twitter_screen_name
  if (salonId) setStaffStateKey(salonId)
  return `/door/${salonId}`
}
export const isValidInvitationStaff = (invitation: Invitation) => {
  return invitation.valid && !invitation.plans
}
export const isValidInvitationGuest = (invitation: Invitation) => {
  return invitation.valid && invitation.plans
}
export const deleteinvitationV1Api = async (
  memberId: string,
  invitationId: string,
  planId?: number
) => {
  const query = `?memberId=${memberId}&invitationId=${invitationId}&planId=${planId}`
  return await requestV1('DELETE', `/invitation${query}`)
}
