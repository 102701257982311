import { createSelector } from 'reselect'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface MeState {
  cognitoId: string
  hasDoor: boolean
  // ユーザー名
  userName: string
  // URLやメンション等に使う
  screenName: string
  userIcon: string
  isSignin: boolean
  // チャネルトーク連携に使う
  email: string
  channeltalkId: string
  currentDoorName: string
  accountLabel: string
  acceptedPrivacyPolicy: boolean
}

/**
 * posts storeの型定義
 */
const initialState: MeState = {
  cognitoId: '',
  hasDoor: false,
  userName: '',
  screenName: '',
  userIcon: '',
  isSignin: false,
  email: '',
  channeltalkId: '',
  currentDoorName: '',
  accountLabel: '',
  acceptedPrivacyPolicy: false
}

/**
 * selector
 * @param state
 * @returns
 */
export const meSelector = (state: RootState) => state.me

export const getHasDoor = createSelector(meSelector, (me) => me.hasDoor)
export const getCognitoId = createSelector(meSelector, (me) => me.cognitoId)
export const getUserName = createSelector(meSelector, (me) => me.userName)
export const getScreenName = createSelector(meSelector, (me) => me.screenName)
export const getEmail = createSelector(meSelector, (me) => me.email)
export const getChanneltalkId = createSelector(
  meSelector,
  (me) => me.channeltalkId
)
export const getUserIcon = createSelector(meSelector, (me) => me.userIcon)
export const getIsSignin = createSelector(meSelector, (me) => me.isSignin)
export const getCurrentDoorName = createSelector(
  meSelector,
  (me) => me.currentDoorName
)
export const getAccountLabel = createSelector(
  meSelector,
  (me) => me.accountLabel
)
export const getAcceptedPrivacyPolicy = createSelector(
  meSelector,
  (me) => me.acceptedPrivacyPolicy
)

/**
 * SLICE
 * reducer
 */
const me = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setCurrentDoorName(state, action: PayloadAction<string>) {
      state.currentDoorName = action.payload
    },
    setHasDoor(state, action: PayloadAction<boolean>) {
      state.hasDoor = action.payload
    },
    setCognitoId(state, action: PayloadAction<string>) {
      state.cognitoId = action.payload
    },
    setUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload
    },
    setScreenName(state, action: PayloadAction<string>) {
      state.screenName = action.payload
    },
    setUserIcon(state, action: PayloadAction<string>) {
      state.userIcon = action.payload
    },
    setIsSignin(state, action: PayloadAction<boolean>) {
      state.isSignin = action.payload
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setChanneltalkId(state, action: PayloadAction<string>) {
      state.channeltalkId = action.payload
    },
    setAcceptedPrivacyPolicy(state, action: PayloadAction<boolean>) {
      state.acceptedPrivacyPolicy = action.payload
    },
    setAccountLabel(state, action: PayloadAction<string>) {
      if (
        action.payload == 'admin_owner' ||
        action.payload == 'general_owner' ||
        action.payload == 'owner'
      ) {
        state.accountLabel = 'オーナー'
      } else if (action.payload == 'general') {
        state.accountLabel = 'スタッフ'
      } else {
        state.accountLabel = ''
      }
    },
    signOut(state) {
      state.screenName = ''
      state.userName = ''
      state.userIcon = ''
      state.email = ''
      state.channeltalkId = ''
      state.isSignin = false
      state.hasDoor = true
      state.cognitoId = ''
      state.currentDoorName = ''
      state.accountLabel = ''
    }
  }
})

export default me
