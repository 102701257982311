import { requestV1 } from './base'
import { Me } from './response_types'

export const getMe = async (doorId?: string) => {
  let path = `/me`
  if(doorId !== undefined) path += `?door_id=${doorId}`
  return await requestV1<Me>('get', path)
}

export const savePromptAnswer = async (doorId?: string) => {
  let path = `/prompt_answer`
  if(doorId !== undefined) path += `?door_id=${doorId}`
  return await requestV1<null>('post', path)
}

export const createMe = async (body: object) => {
  return await requestV1<Me>('post', '/me', body)
}

export const enableAllPushNotifications = () => {
  return updateMe({
    push_settings: {
      owner: {
        new_member: true,
        new_comment: true,
        mention: true,
        like: true
      },
      member: {
        new_post: true,
        mention: true,
        like: true
      }
    }
  })
}

export const createEndpoint = async (body: object) => {
  return await requestV1<void>('post', '/me/endpoints', body)
}

export const getPaymentMethod = async () => {
  return await requestV1<{ card_number: string }>('get', '/me/payment_method')
}

export const createPaymentMethod = async (
  token: string,
  expiryYear: string,
  expiryMonth: string
) => {
  return await requestV1<void>('post', '/me/payment_method', {
    token,
    expiry_year: expiryYear,
    expiry_month: expiryMonth
  })
}

export const updatePaymentMethod = async (payment_method_id: string) => {
  return await requestV1<void>('patch', '/me/payment_method', {
    payment_method_id
  })
}

export const updateMe = async (body:{ [key: string]: any }, doorId?: string) => {
  if(doorId !== undefined) body['door_id']  = doorId
  return await requestV1<Me>('patch', '/me', body)
}

export const updateEmail = async (email: string) => {
  return await requestV1<Me>('post', '/me/email', { email })
}

export const confirmEmail = async (confirmation_code: string) => {
  return await requestV1<Me>('post', '/me/email/confirm', { confirmation_code })
}

export const postPrivacyPolicyAgreements = async () => {
  return await requestV1<null>('post', '/me/privacy_policy_agreements')
}