import { API, Auth } from 'aws-amplify'
import AWS from 'aws-sdk'

import awsconfig from '../aws-exports'
import { logger } from './logger'
import { cognitoSignin } from 'apis/user_credentials'
import { CognitoSigninToken } from 'apis/response_types'
import { getTwitterRequestTokenURL, verifyTwitterCredentials } from 'apis/oauth'
import constants from '../constants'

const {
  amplifyFederatedInfoKey,
  amplifySigninWithHostedUIKey,
  cognitoIdentityIdKeyPrefix,
  cognitoIdentityProvidersKeyPrefix
} = constants

const urlBase = awsconfig.twitter_sls_api_url_base

const TwitterConnect = () => {
  const cleanLocalStorage = async () => {
    localStorage.removeItem(amplifyFederatedInfoKey)
    localStorage.removeItem(amplifySigninWithHostedUIKey)
    const iidKey =
      cognitoIdentityIdKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    localStorage.removeItem(iidKey)
    const iipKey =
      cognitoIdentityProvidersKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    localStorage.removeItem(iipKey)
  }
  const postBody = async (endpoint: string, body: any) => {
    const twitterRequest = await fetch(urlBase + endpoint, {
      method: 'POST',
      body: JSON.stringify(body)
    }).catch((err) => {
      logger.error(err)
    })
    if (twitterRequest) {
      const twitterResponse = await twitterRequest.json()
      logger.debug(twitterRequest)
      logger.debug(twitterResponse)
      return twitterResponse
    }
  }

  /**
   * localStrageのamplify federatedInfoのデータをもとにyoor APIを実行し認証情報を取得する
   */
  const getCognitoSigninToken: () => Promise<CognitoSigninToken> = async () => {
    const info = localStorage.getItem(amplifyFederatedInfoKey)
    if (!info) throw new Error('no federated info')

    const infoParsed = JSON.parse(info)
    const token = infoParsed.token.split(';')
    const iidKey =
      cognitoIdentityIdKeyPrefix + awsconfig.aws_cognito_identity_pool_id
    const identityId = localStorage.getItem(iidKey)

    const response = await cognitoSignin({
      provider: 'twitter',
      oauth_twitter_token: token[0],
      oauth_twitter_token_secret: token[1],
      screen_name: infoParsed.user.name,
      identity_id: identityId
    })
    return response.data
  }

  const handleSignInConnect = async (event: any, referrer?: string) => {
    if (event) {
      event.preventDefault()
    }
    // ログイン前に遷移しようとしたページがある場合コールバックURLのパラメーターにページのURLを設定する
    const callbackUrl = referrer ? `${awsconfig.twitter_signin_callback_url}?referrer=${referrer}` : awsconfig.twitter_signin_callback_url
    try {
      const resp: any = await getTwitterRequestTokenURL({
        callback_url: callbackUrl
      })
      logger.debug(resp)
      window.location.href = resp.data.url
    } catch(err) {
      window.location.href = '/'
    }
  }

  const handleSignUpConnect = async (event: any) => {
    if (event) {
      event.preventDefault()
    }
    try {
      const resp: any = await getTwitterRequestTokenURL({
        callback_url: awsconfig.twitter_signup_callback_url
      })
      logger.debug(resp)
      window.location.href = resp.data.url
    } catch (err) {
      window.location.href = '/'
    }
  }

  const handleConnect = async (event: any) => {
    if (event) {
      event.preventDefault()
    }
    try {
      const resp: any = await getTwitterRequestTokenURL({
        callback_url: awsconfig.twitter_connect_callback_url
      })
      logger.debug(resp)
      window.location.href = resp.data.url
    } catch (err) {
      window.location.href = '/'
    }
  }

  const getIdentityId = async (token: string) => {
    const credential = await new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsconfig.aws_cognito_identity_pool_id,
      Logins: { 'api.twitter.com': token }
    }, { region: awsconfig.aws_cognito_region })

    try {
      await credential.getPromise()
    } catch(err) {
      if (err) {
        logger.debug('Error: ' + err)
        return ''
      }
    }
    logger.debug('Cognito Identity Id: ' + credential.identityId)
    return credential.identityId
  }

  const federatedSignIn = async (twitter: any) => {
    const token =
      twitter.token.oauth_token + ';' + twitter.token.oauth_token_secret

    const identityId = await getIdentityId(token)

    const cred = await Auth.federatedSignIn('api.twitter.com', {
      token: token,
      identity_id: identityId,
      expires_at: 3600 * 24 * 30 * 1000 + new Date().getTime() // the expiration timestamp
    }, {
      name: twitter.user.screen_name,
      email: twitter.user.email
    })

    return {
      credentials: cred,
      identityId: identityId
    }
  }

  const verifyCredentials = async (token: string, verifier: string) => {
    try {
      const resp: any = await verifyTwitterCredentials({
        oauth_token: token,
        oauth_verifier: verifier
      })
      return resp.data
    } catch (err) {
      window.location.href = '/'
    }
  }

  return {
    getCognitoSigninToken: getCognitoSigninToken,
    handleSignInConnect: handleSignInConnect,
    handleSignupConnect: handleSignUpConnect,
    handleConnect: handleConnect,
    verifyCredentials: verifyCredentials,
    federatedSignIn: federatedSignIn,
    cleanLocalStorage: cleanLocalStorage
  }
}

const Twitter = TwitterConnect()

export default Twitter
